<template>
  <div class="main-card" a-tag="followerCard">
    <div class="text-center p-3" v-if="leaderLoading">
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <followers v-if="leader && leader.followers.data.length" :leaders="leader.followers.data" />

      <empty v-else :title="$t('LEADERS.NO_FOLLOWERS')" />
    </div>
  </div>
</template>

<script>
import { getLeaderRequest } from "@/api/leaders";
import { mapGetters } from "vuex";
import Empty from "@/components/Shared/Empty";
import Followers from "@/components/Application/Leaders/Followers";

export default {
  components: {
    Empty,
    Followers,
  },
  data() {
    return {
      leaderLoading: false,
      leader: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    if (this.user?.is_leader) {
      this.handleLoadLeader();
    }
  },
  methods: {
    handleLoadLeader() {
      const params = {
        id: this.user.leader.data.id,
        include: "followers",
      };
      this.leaderLoading = true;
      this.ApiService(getLeaderRequest(params))
        .then(({ data }) => {
          this.leader = data.data;
        })
        .finally(() => {
          this.leaderLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
