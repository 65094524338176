<template>
  <div>
    <div v-if="leaders.length">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h4 class="search-title">{{ $t("LEADERS.YOUR_FOLLOWERS") }}</h4>
        <TextField
          a-tag="explorerSearchButton"
          type="search"
          name="search"
          v-model.trim="search"
          :placeholder="$t('GLOBAL_SEARCH')"
        />
      </div>
      <div v-if="filteredFollowers.length">
        <b-table class="custom-table" responsive :fields="fields" :items="filteredFollowers">
          <template #cell(name)="data">
            <div class="d-flex align-items-center name-container">
              <image-circle :defaultImage="data.item.image" :showCoin="true" />
              <div class="ml-2">
                <h4>
                  {{ data.item.name }}
                </h4>
                <span>
                  {{ `${$t("LEADERS.FOLLOWED")} ${data.item.readable_created_at}` }}
                </span>
              </div>
            </div>
          </template>
          <!-- <template #cell(exchange)="data">
          <img
            v-for="(exchange, index) in data.item.exchanges.data"
            :key="index"
            :src="exchange.exchange_logo"
            alt=""
            class="exhange-logo"
          />
        </template> -->

          <!-- <template #cell(month)="data">
          <h4 class="change-title">
            {{ data.item.monthly_change }}
          </h4>
        </template> -->

          <template #cell(performance)="data">
            <line-chart
              v-if="data.item.wallets"
              :chartdata="handleSetChartData(data.item.wallets)"
              :options="options"
              class="chart"
            />
          </template>
          <template #cell(assets)="data">
            <h4 class="follower-title" v-if="data.item.wallets.data.length">
              $ {{ data.item.wallets.data[0].current_usd_balance }}
            </h4>
          </template>
        </b-table>
      </div>
      <div class="text-center" v-else>
        <h6 class="py-3">
          {{ $t("GLOBAL_NO_MESSAGE") }}
        </h6>
      </div>
    </div>
    <div class="text-center" v-else>
      <h6 class="py-3">
        {{ $t("GLOBAL_NO_MESSAGE") }}
      </h6>
    </div>
  </div>
</template>

<script>
import ImageCircle from "@/components/Shared/ImageCircle";
import { getDateFormat } from "@/helpers";
import LineChart from "@/components/Application/Chart/LineChart";

export default {
  props: {
    leaders: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ImageCircle,
    LineChart,
  },
  watch: {
    leaders: {
      immediate: true,
      handler() {
        this.filteredFollowers = this.leaders;
      },
    },
    search() {
      this.filteredFollowers = this.leaders.filter((leader) =>
        leader.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  data() {
    return {
      search: null,
      filteredFollowers: [],
      fields: [
        { key: "name", sortable: true, label: this.$t("LEADERS.USER_ID") },
        // {
        //   key: "exchange",
        //   label: this.$t("LEADERS.EXCHANGE"),
        // },
        // { key: "month", sortable: true, label: this.$t("LEADERS.MONTH") },
        {
          key: "performance",
          sortable: false,
          label: this.$t("LEADERS.PERFORMANCE"),
        },
        {
          key: "assets",
          sortable: false,
          label: this.$t("LEADERS.ASSETS"),
        },
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,

        tooltips: {
          enabled: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    };
  },
  methods: {
    handleSetChartData(wallet) {
      let chartLabels = [];
      let chartValue = [];
      if (wallet.data.length) {
        chartLabels = wallet.data[0]?.wallet_data?.map((chart) =>
          getDateFormat(new Date(chart.updateTime), "DD/MM")
        );
        chartValue = wallet.data[0]?.wallet_data?.map((chart) => chart.data.totalAssetOfBtc);
      }

      return {
        labels: chartLabels,
        datasets: [
          {
            data: chartValue,
            backgroundColor: "transparent",
            borderColor: "#532680",
            pointBackgroundColor: "#532680",
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
